
import * as Data from '@/../src-gen/data';
import {InsightStoreMutations} from '@/../src-gen/store/insight-store-support';

export class InsightStoreMutationsCode implements InsightStoreMutations   {


   public updateDemographyOutput(state: Data.Insight.InsightStore, demographyOutput: Data.Insight.DemographyOutput[]  ): void  {
      state.demographyOutput = demographyOutput
   }


   public updatePartnerClientConversionFunnelOutput(state: Data.Insight.InsightStore, partnerClientConversionFunnelOutput: Data.Insight.PartnerClientConversionFunnelOutput  ): void  {
     state.partnerClientConversionFunnelOutput = partnerClientConversionFunnelOutput
   }


   public updateDailyFunnelOutput(state: Data.Insight.InsightStore, dailyFunnelOutput: Data.Insight.DailyFunnelOutput[]  ): void  {
     state.dailyFunnelOutput = dailyFunnelOutput
   }


   public updateMonthlyFunnelOutput(state: Data.Insight.InsightStore, monthlyFunnelOutput: Data.Insight.MonthlyFunnelOutput[]  ): void  {
     state.monthlyFunnelOutput = monthlyFunnelOutput
   }


   public updateConversionSummaryOutput(state: Data.Insight.InsightStore, conversionSummaryOutput: Data.Insight.ConversionSummaryOutput  ): void  {
     state.conversionSummaryOutput = conversionSummaryOutput
   }

   public updateSearchCriteria(state: Data.Insight.InsightStore, searchCriteria: Data.Insight.SearchClientFileInput): void {
    state.searchCriteria = searchCriteria
  }
  public updateSearchResultList(state: Data.Insight.InsightStore, searchResultList: Data.Insight.SearchClientFileOutput): void {
    state.searchResultList = searchResultList
  }

}

