import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const spineApi = axios.create({
  baseURL: process.env.VUE_APP_SPINE_API_URL,
});
// const spineApi = axios.create({
//   baseURL: process.env.VUE_APP_API_URL,
// });

spineApi.interceptors.request.use(requestInterceptor, error);
spineApi.interceptors.response.use(responseInterceptor, error);

api.interceptors.request.use(requestInterceptor, error);
api.interceptors.response.use(responseInterceptor, error);

function requestInterceptor(config: any) {
  const token = localStorage.getItem('auth_token') || '';
  if (token && !config?.headers?.Authorization) {
    config.headers.Authorization = token;
  }
  // if (config.headers.resetHeaders) {
  //   config.headers = {};
  // }
  return config;
}
function responseInterceptor(response: any) {
  return response;
}
function error(error: any) {
  return Promise.reject(error);
}

export const PartnerProgramApi = api;
export const GompaApi = api;
export const SpineApi = spineApi;
export const ClientAppApi = api;
export const ValeyApi = api;
export const BenchApi = api;
export const DspApi = spineApi;
export const PaymentApi = spineApi;
export const InsightApi = axios.create({
  baseURL: process.env.VUE_APP_INSIGHT_API
    ? process.env.VUE_APP_INSIGHT_API
    : 'https://prepord-insight.freed.care/',
  // timeout: 5000,
  headers: {
    // Authorization: localStorage.getItem('auth_token')||""
  },
});
InsightApi.interceptors.request.use((config: any) => {
  config.headers.common.Authorization =
    localStorage.getItem('auth_token') || '';
  return config;
});
export default api;
