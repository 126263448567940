
import * as Data from '@/../src-gen/data';
import {ClientFileSummaryMutations} from '@/../src-gen/store/clientfile-store-support';

export class ClientFileSummaryMutationsCode implements ClientFileSummaryMutations   {


   public updateFiEMandateList(state: Data.ClientFile.ClientFileSummary, fiEMandateList: Data.ClientFile.FiEMandateList[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateClientFileBasicInfo(state: Data.ClientFile.ClientFileSummary, clientFileBasicInfo: Data.ClientFile.ClientFileBasicInfo  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiBankInfo(state: Data.ClientFile.ClientFileSummary, fiBankInfo: Data.ClientFile.FiBankInfo|undefined  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiCreditorInfo(state: Data.ClientFile.ClientFileSummary, fiCreditorInfo: Data.ClientFile.FiCreditorInfo|undefined  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiDocumentList(state: Data.ClientFile.ClientFileSummary, fiDocumentList: Data.ClientFile.FiDocument[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiPaymentPlan(state: Data.ClientFile.ClientFileSummary, fiPaymentPlanInfo: Data.ClientFile.FiPaymentPlanInfo|undefined  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateBudgetInfo(state: Data.ClientFile.ClientFileSummary, budgetInfo: Data.ClientFile.BudgetInfo|undefined  ): void  {
     throw new Error('Not implemented YET');
   }


   public updatePersonalInfo(state: Data.ClientFile.ClientFileSummary, personalInfo: Data.ClientFile.ClPersonalInfo|undefined  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateClCreditorList(state: Data.ClientFile.ClientFileSummary, clCreditorList: Data.ClientFile.ClCreditor[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiEMandateSummaryList(state: Data.ClientFile.ClientFileSummary, fiEMandateSummaryList: Data.ClientFile.FiEMandateSummary[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiSignAgreementList(state: Data.ClientFile.ClientFileSummary, fiSignAgreementList: Data.ClientFile.FiSSASummary[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiPaymentList(state: Data.ClientFile.ClientFileSummary, fiPaymentList: Data.ClientFile.FiPayment[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFiPaymentTransactionList(state: Data.ClientFile.ClientFileSummary, fiPaymentTransactionList: Data.ClientFile.FiPaymentTransaction[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateFileSummary(state: Data.ClientFile.ClientFileSummary, fileSummary: Data.ClientFile.FileSummary  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateClientFileEnrollmentSummary(state: Data.ClientFile.ClientFileSummary, fiClientFileEnrollmentSummary: Data.ClientFile.ClientFileEnrollmentSummary  ): void  {
     throw new Error('Not implemented YET');
   }


   public resetCFSummary(state: Data.ClientFile.ClientFileSummary  ): void  {
     throw new Error('Not implemented YET');
   }


}

