
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import * as Snackbar from "node-snackbar";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FSelectFieldMDP from "@/components/form/field/FSelectFieldMDP";
import FEMandateSelectFieldMDP from "@/components/form/field/FEMandateSelectFieldMDP";
import FSelectDateFieldMDP from "@/components/form/field/FDateSelectFieldMDP";
import FCurrencyFieldMDP from "@/components/form/field/FCurrencyFieldMDP";
import FBtnMDP from "@/components/FBtnMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";

export default class AddPsEntryFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  parent: any;
  constructor({
    parent,
  }: {
    parent: any;
  }) {
    super({
      myRefName: "addPsEntryFFormRef",
    });
    this.parent = parent;

    this.addField(
      new FSelectFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "paymentProvider",
        label: "Payment Provider",
        mandatory: true,
        options: Data.ClientFile.PAYMENT_PROVIDER.list(),
        boundaryClass: "col-4",
        optionLabel: "name",
        optionValue: "id"
      })
    ).addField(
      new FSelectFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "paymentMode",
        label: "Payment Mode",
        mandatory: true,
        options: Data.ClientFile.PAYMENT_MODE.list(),
        boundaryClass: "col-4",
        optionLabel: "name",
        optionValue: "id"
      })
    ).addField(
      new FEMandateSelectFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "eMandateId",
        label: "E-Mandate",
        mandatory: true,
        boundaryClass: "col-4",
      })
    ).addField(
      new FSelectDateFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "draftDate",
        label: "Draft Date",
        mandatory: true,
        boundaryClass: "col-4",
      })
    ).addField(new FCurrencyFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "spaAmount",
        label: "SPA Amount",
        boundaryClass: "col-4",
    })).addField(new FCurrencyFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "feeAmount",
        boundaryClass: "col-4",
        label: "Fee Amount"
    })).addAction(
      new FBtnMDP({
        label: "Cancel",
        onClick: () => this.parent.resetFormsTableAndData(),
      })
    ).addAction(
      new FFormSubmitBtnMDP({
        label: "Add Entry",
        onClick: () => this.addEntry(),
      })
    );
  }

  getMyRef() {
    return this.parent.$refs[this.myRefName];
  }

  addEntry() {
    this.parent.addPsEntryInput.clientFileId = this.parent.clientFileId;
    // Action.ClientFile.AddPSEntry.execute(this.parent.addPsEntryInput, output => {
    //   this.parent.resetFormsTableAndData();
    //   Snackbar.show({
    //     text: "Succesfully added an entry",
    //     pos: "bottom-center",
    //   });
    // });
  }


}
