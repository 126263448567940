
import * as Data from '@/../src-gen/data';
import {MyTaskStoreMutations} from '@/../src-gen/store/tasklist-store-support';

export class MyTaskStoreMutationsCode implements MyTaskStoreMutations   {


   public updateMyActiveTaskList(state: Data.TaskList.MyTaskStore, activeTaskList: Data.TaskList.GetActiveTaskListAllocatedGrid[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateCompletedTaskList(state: Data.TaskList.MyTaskStore, completedTaskList: Data.TaskList.CompletedTaskGrid[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateMySuspendedTaskList(state: Data.TaskList.MyTaskStore, suspendedTaskList: Data.TaskList.SuspendedTaskAllocated[]  ): void  {
     throw new Error('Not implemented YET');
   }


   public updateTaskCommentList(state: Data.TaskList.MyTaskStore, taskCommentList: Data.TaskList.Comment[]  ): void  {
     throw new Error('Not implemented YET');
   }


}

