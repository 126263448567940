
import * as Data from '@/../src-gen/data';
import {SummaryGetters} from '@/../src-gen/store/tasklist-store-support';

export class SummaryInit {
    public static initState(): Data.TaskList.Summary  { return new Data.TaskList.Summary(); }
}

export class SummaryGettersCode implements SummaryGetters {


   public executiveTaskDetails(state: Data.TaskList.Summary): Data.TaskList.ExecutiveTaskDetails {
     throw new Error('Not implemented YET');
   }


   public isTaskOutputSchemaEmpty(state: Data.TaskList.Summary): boolean {
     throw new Error('Not implemented YET');
   }


   public isTaskInputSchemaEmpty(state: Data.TaskList.Summary): boolean {
     throw new Error('Not implemented YET');
   }

}
