
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import PartnerAnalyticsReaderService from './../service/insightapi/PartnerAnalyticsReaderService';
import MisReportsReaderService from './../service/insightapi/MisReportsReaderService';

// tslint:disable



export class GetPartnerClientList {

    

    

    public static execute(input: Data.Insight.SearchClientFileInput, f: (output: Data.Insight.SearchClientFileOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getPartnerClientList(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           Store.Mutation.Insight.InsightStore.UPDATE_SEARCH_CRITERIA(input); 

           Store.Mutation.Insight.InsightStore.UPDATE_SEARCH_RESULT_LIST(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.SearchClientFileInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Input {
        return new GetPartnerClientList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Output): Data.Insight.SearchClientFileOutput {
        return new GetPartnerClientList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerClientList$Mapper {

    public mapInput(input: Data.Insight.SearchClientFileInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Input {
        return ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Output): Data.Insight.SearchClientFileOutput {
        return Data.Insight.SearchClientFileOutput.fromJson(output.toJson());
    }

}

class GetPartnerClientList$MapperCode extends GetPartnerClientList$Mapper {

}

       


export class GetDailySalesData {

    

    

    public static execute(input: Data.Insight.GetDailySalesDataInput, f: (output: Data.Insight.DailySalesDataInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        MisReportsReaderService.getDailySalesData(axoisInstance, this.mapInput(input),
            ((output: ServerData.MisReportsWebReader.GetDailySalesData$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetDailySalesDataInput): ServerData.MisReportsWebReader.GetDailySalesData$Input {
        return new GetDailySalesData$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MisReportsWebReader.GetDailySalesData$Output): Data.Insight.DailySalesDataInfo {
        return new GetDailySalesData$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetDailySalesData$Mapper {

    public mapInput(input: Data.Insight.GetDailySalesDataInput): ServerData.MisReportsWebReader.GetDailySalesData$Input {
        return ServerData.MisReportsWebReader.GetDailySalesData$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.MisReportsWebReader.GetDailySalesData$Output): Data.Insight.DailySalesDataInfo {
        return Data.Insight.DailySalesDataInfo.fromJson(output.toJson());
    }

}

class GetDailySalesData$MapperCode extends GetDailySalesData$Mapper {

}

       


export class GetCollectionReport {

    

    

    public static execute(input: Data.Insight.GetCollectionReportInput, f: (output: Data.Insight.CollectionReportInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getCollectionReport(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetCollectionReportInput): ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Input {
        return new GetCollectionReport$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Output): Data.Insight.CollectionReportInfo {
        return new GetCollectionReport$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetCollectionReport$Mapper {

    public mapInput(input: Data.Insight.GetCollectionReportInput): ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Input {
        return ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Output): Data.Insight.CollectionReportInfo {
        return Data.Insight.CollectionReportInfo.fromJson(output.toJson());
    }

}

class GetCollectionReport$MapperCode extends GetCollectionReport$Mapper {

}

       


export class GetPartnerClientListForRange {

    

    

    public static execute(input: Data.Insight.GetPartnerClientListForRangeInput, f: (output: Data.Insight.PartnerClientListForRangeInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getPartnerClientListForRange(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetPartnerClientListForRangeInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input {
        return new GetPartnerClientListForRange$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output): Data.Insight.PartnerClientListForRangeInfo {
        return new GetPartnerClientListForRange$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerClientListForRange$Mapper {

    public mapInput(input: Data.Insight.GetPartnerClientListForRangeInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input {
        return ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output): Data.Insight.PartnerClientListForRangeInfo {
        return Data.Insight.PartnerClientListForRangeInfo.fromJson(output.toJson());
    }

}

class GetPartnerClientListForRange$MapperCode extends GetPartnerClientListForRange$Mapper {

}

       


export class GetPartnerClientCount {

    

    
    public static execute2(fromDate: string, toDate: string, f: (output: Data.Insight.GetPartnerClientCountOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {
      GetPartnerClientCount.execute(new Data.Insight.GetPartnerClientCountInput(fromDate, toDate), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Insight.GetPartnerClientCountInput, f: (output: Data.Insight.GetPartnerClientCountOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getPartnerClientCount(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetPartnerClientCountInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Input {
        return new GetPartnerClientCount$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Output): Data.Insight.GetPartnerClientCountOutput {
        return new GetPartnerClientCount$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerClientCount$Mapper {

    public mapInput(input: Data.Insight.GetPartnerClientCountInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Input {
        return ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Output): Data.Insight.GetPartnerClientCountOutput {
        return Data.Insight.GetPartnerClientCountOutput.fromJson(output.toJson());
    }

}

class GetPartnerClientCount$MapperCode extends GetPartnerClientCount$Mapper {

}

       


export class FindParticularPartnerClient {

    

    
    public static execute2(mobile: string|undefined, clientFileNumber: string|undefined, f: (output: Data.Insight.FindParticularPartnerClientOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {
      FindParticularPartnerClient.execute(new Data.Insight.FindParticularPartnerClientInput(mobile, clientFileNumber), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Insight.FindParticularPartnerClientInput, f: (output: Data.Insight.FindParticularPartnerClientOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.findParticularPartnerClient(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$FindParticularPartnerClientOutput) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.FindParticularPartnerClientInput): ServerData.PartnerAnalyticsWebReader.FindParticularPartnerClient$Input {
        return new FindParticularPartnerClient$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$FindParticularPartnerClientOutput): Data.Insight.FindParticularPartnerClientOutput {
        return new FindParticularPartnerClient$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class FindParticularPartnerClient$Mapper {

    public mapInput(input: Data.Insight.FindParticularPartnerClientInput): ServerData.PartnerAnalyticsWebReader.FindParticularPartnerClient$Input {
        return ServerData.PartnerAnalyticsWebReader.FindParticularPartnerClient$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.InsightData$FindParticularPartnerClientOutput): Data.Insight.FindParticularPartnerClientOutput {
        return Data.Insight.FindParticularPartnerClientOutput.fromJson(output.toJson());
    }

}

class FindParticularPartnerClient$MapperCode extends FindParticularPartnerClient$Mapper {

}

       


export class GetPartnerClientFunnelForFpp {

    

    
    public static execute2(fromDate: string, toDate: string, f: (output: Data.Insight.PartnerClientConversionFunnelForFpp) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {
      GetPartnerClientFunnelForFpp.execute(new Data.Insight.GetPartnerClientFunnelForFppInput(fromDate, toDate), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Insight.GetPartnerClientFunnelForFppInput, f: (output: Data.Insight.PartnerClientConversionFunnelForFpp) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getPartnerClientFunnelForFpp(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$PartnerClientConversionFunnelForFpp) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetPartnerClientFunnelForFppInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input {
        return new GetPartnerClientFunnelForFpp$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$PartnerClientConversionFunnelForFpp): Data.Insight.PartnerClientConversionFunnelForFpp {
        return new GetPartnerClientFunnelForFpp$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerClientFunnelForFpp$Mapper {

    public mapInput(input: Data.Insight.GetPartnerClientFunnelForFppInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input {
        return ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.InsightData$PartnerClientConversionFunnelForFpp): Data.Insight.PartnerClientConversionFunnelForFpp {
        return Data.Insight.PartnerClientConversionFunnelForFpp.fromJson(output.toJson());
    }

}

class GetPartnerClientFunnelForFpp$MapperCode extends GetPartnerClientFunnelForFpp$Mapper {

}

       


export class GetPartnerClientDemography {

    

    

    public static execute( f: (output: Data.Insight.DemographyOutput[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    const input = new ServerData.PartnerAnalyticsWebReader.GetPartnerClientDemography$Input();

    

        PartnerAnalyticsReaderService.getPartnerClientDemography(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$Demography[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Insight.InsightStore.UPDATE_DEMOGRAPHY_OUTPUT(mappedOutput.map((x) => Data.Insight.DemographyOutput.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientDemography$Input): ServerData.PartnerAnalyticsWebReader.GetPartnerClientDemography$Input {
        return new GetPartnerClientDemography$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$Demography[]): Data.Insight.DemographyOutput[] {
        return new GetPartnerClientDemography$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerClientDemography$Mapper {

    public mapInput(input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientDemography$Input): ServerData.PartnerAnalyticsWebReader.GetPartnerClientDemography$Input {
        return input; 
    }

    public mapOutput(output: ServerData.InsightData$Demography[]): Data.Insight.DemographyOutput[] {
        return  output.map((x) => Data.Insight.DemographyOutput.fromJson(x.toJson()));
    }

}

class GetPartnerClientDemography$MapperCode extends GetPartnerClientDemography$Mapper {

}

       


export class GetPartnerClientFunnel {

    

    
    public static execute2(fromDate: string, toDate: string, f: (output: Data.Insight.PartnerClientConversionFunnelOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {
      GetPartnerClientFunnel.execute(new Data.Insight.GetPartnerClientFunnelInput(fromDate, toDate), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Insight.GetPartnerClientFunnelInput, f: (output: Data.Insight.PartnerClientConversionFunnelOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getPartnerClientFunnel(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$PartnerClientConversionFunnel) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Insight.InsightStore.UPDATE_PARTNER_CLIENT_CONVERSION_FUNNEL_OUTPUT(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetPartnerClientFunnelInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input {
        return new GetPartnerClientFunnel$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$PartnerClientConversionFunnel): Data.Insight.PartnerClientConversionFunnelOutput {
        return new GetPartnerClientFunnel$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerClientFunnel$Mapper {

    public mapInput(input: Data.Insight.GetPartnerClientFunnelInput): ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input {
        return ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.InsightData$PartnerClientConversionFunnel): Data.Insight.PartnerClientConversionFunnelOutput {
        return Data.Insight.PartnerClientConversionFunnelOutput.fromJson(output.toJson());
    }

}

class GetPartnerClientFunnel$MapperCode extends GetPartnerClientFunnel$Mapper {

}

       


export class GetDailyFunnel {

    

    
    public static execute2(fromDate: string, toDate: string, f: (output: Data.Insight.DailyFunnelOutput[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {
      GetDailyFunnel.execute(new Data.Insight.GetDailyFunnelInput(fromDate, toDate), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Insight.GetDailyFunnelInput, f: (output: Data.Insight.DailyFunnelOutput[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getDailyFunnel(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$DailyFunnel[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Insight.InsightStore.UPDATE_DAILY_FUNNEL_OUTPUT(mappedOutput.map((x) => Data.Insight.DailyFunnelOutput.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetDailyFunnelInput): ServerData.PartnerAnalyticsWebReader.GetDailyFunnel$Input {
        return new GetDailyFunnel$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$DailyFunnel[]): Data.Insight.DailyFunnelOutput[] {
        return new GetDailyFunnel$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetDailyFunnel$Mapper {

    public mapInput(input: Data.Insight.GetDailyFunnelInput): ServerData.PartnerAnalyticsWebReader.GetDailyFunnel$Input {
        return ServerData.PartnerAnalyticsWebReader.GetDailyFunnel$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.InsightData$DailyFunnel[]): Data.Insight.DailyFunnelOutput[] {
        return  output.map((x) => Data.Insight.DailyFunnelOutput.fromJson(x.toJson()));
    }

}

class GetDailyFunnel$MapperCode extends GetDailyFunnel$Mapper {

}

       


export class GetMonthlyFunnel {

    

    
    public static execute2(fromDate: string, toDate: string, f: (output: Data.Insight.MonthlyFunnelOutput[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {
      GetMonthlyFunnel.execute(new Data.Insight.GetMonthlyFunnelInput(fromDate, toDate), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Insight.GetMonthlyFunnelInput, f: (output: Data.Insight.MonthlyFunnelOutput[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        PartnerAnalyticsReaderService.getMonthlyFunnel(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$MonthlyFunnel[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Insight.InsightStore.UPDATE_MONTHLY_FUNNEL_OUTPUT(mappedOutput.map((x) => Data.Insight.MonthlyFunnelOutput.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetMonthlyFunnelInput): ServerData.PartnerAnalyticsWebReader.GetMonthlyFunnel$Input {
        return new GetMonthlyFunnel$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$MonthlyFunnel[]): Data.Insight.MonthlyFunnelOutput[] {
        return new GetMonthlyFunnel$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetMonthlyFunnel$Mapper {

    public mapInput(input: Data.Insight.GetMonthlyFunnelInput): ServerData.PartnerAnalyticsWebReader.GetMonthlyFunnel$Input {
        return ServerData.PartnerAnalyticsWebReader.GetMonthlyFunnel$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.InsightData$MonthlyFunnel[]): Data.Insight.MonthlyFunnelOutput[] {
        return  output.map((x) => Data.Insight.MonthlyFunnelOutput.fromJson(x.toJson()));
    }

}

class GetMonthlyFunnel$MapperCode extends GetMonthlyFunnel$Mapper {

}

       


export class GetSaleCountDayWiseList {

    

    

    public static execute(input: Data.Insight.GetSaleCountDayWiseListInput, f: (output: Data.Insight.SaleCountDayWiseDataInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        MisReportsReaderService.getSaleCountDayWiseList(axoisInstance, this.mapInput(input),
            ((output: ServerData.MisReportsWebReader.GetSaleCountDayWiseList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetSaleCountDayWiseListInput): ServerData.MisReportsWebReader.GetSaleCountDayWiseList$Input {
        return new GetSaleCountDayWiseList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MisReportsWebReader.GetSaleCountDayWiseList$Output): Data.Insight.SaleCountDayWiseDataInfo {
        return new GetSaleCountDayWiseList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetSaleCountDayWiseList$Mapper {

    public mapInput(input: Data.Insight.GetSaleCountDayWiseListInput): ServerData.MisReportsWebReader.GetSaleCountDayWiseList$Input {
        return ServerData.MisReportsWebReader.GetSaleCountDayWiseList$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.MisReportsWebReader.GetSaleCountDayWiseList$Output): Data.Insight.SaleCountDayWiseDataInfo {
        return Data.Insight.SaleCountDayWiseDataInfo.fromJson(output.toJson());
    }

}

class GetSaleCountDayWiseList$MapperCode extends GetSaleCountDayWiseList$Mapper {

}

       


export class GetMandateCountDayWiseList {

    

    

    public static execute(input: Data.Insight.GetMandateCountDayWiseListInput, f: (output: Data.Insight.MandateCountDayWiseDataInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    

    

        MisReportsReaderService.getMandateCountDayWiseList(axoisInstance, this.mapInput(input),
            ((output: ServerData.MisReportsWebReader.GetMandateCountDayWiseList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Insight.GetMandateCountDayWiseListInput): ServerData.MisReportsWebReader.GetMandateCountDayWiseList$Input {
        return new GetMandateCountDayWiseList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MisReportsWebReader.GetMandateCountDayWiseList$Output): Data.Insight.MandateCountDayWiseDataInfo {
        return new GetMandateCountDayWiseList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetMandateCountDayWiseList$Mapper {

    public mapInput(input: Data.Insight.GetMandateCountDayWiseListInput): ServerData.MisReportsWebReader.GetMandateCountDayWiseList$Input {
        return ServerData.MisReportsWebReader.GetMandateCountDayWiseList$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.MisReportsWebReader.GetMandateCountDayWiseList$Output): Data.Insight.MandateCountDayWiseDataInfo {
        return Data.Insight.MandateCountDayWiseDataInfo.fromJson(output.toJson());
    }

}

class GetMandateCountDayWiseList$MapperCode extends GetMandateCountDayWiseList$Mapper {

}

       


export class GetPartnerConversionSummary {

    

    

    public static execute( f: (output: Data.Insight.ConversionSummaryOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.InsightApi): void {

    const input = new ServerData.PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input();

    

        PartnerAnalyticsReaderService.getPartnerConversionSummary(axoisInstance, this.mapInput(input),
            ((output: ServerData.InsightData$ConversionSummary) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Insight.InsightStore.UPDATE_CONVERSION_SUMMARY_OUTPUT(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input): ServerData.PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input {
        return new GetPartnerConversionSummary$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.InsightData$ConversionSummary): Data.Insight.ConversionSummaryOutput {
        return new GetPartnerConversionSummary$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerConversionSummary$Mapper {

    public mapInput(input: ServerData.PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input): ServerData.PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input {
        return input; 
    }

    public mapOutput(output: ServerData.InsightData$ConversionSummary): Data.Insight.ConversionSummaryOutput {
        return Data.Insight.ConversionSummaryOutput.fromJson(output.toJson());
    }

}

class GetPartnerConversionSummary$MapperCode extends GetPartnerConversionSummary$Mapper {

}

       

     