
import * as Data from '@/../src-gen/data';
import {MyTaskStoreGetters} from '@/../src-gen/store/tasklist-store-support';

export class MyTaskStoreInit {
    public static initState(): Data.TaskList.MyTaskStore  { return new Data.TaskList.MyTaskStore(); }
}

export class MyTaskStoreGettersCode implements MyTaskStoreGetters {


   public myActiveTaskList(state: Data.TaskList.MyTaskStore): Data.TaskList.GetActiveTaskListAllocatedGrid[] {
     throw new Error('Not implemented YET');
   }


   public myCompletedTaskList(state: Data.TaskList.MyTaskStore): Data.TaskList.CompletedTaskGrid[] {
     throw new Error('Not implemented YET');
   }


   public mySuspendedTaskList(state: Data.TaskList.MyTaskStore): Data.TaskList.SuspendedTaskAllocated[] {
     throw new Error('Not implemented YET');
   }


   public myTaskDashboardSummary(state: Data.TaskList.MyTaskStore): Data.TaskList.MyTaskDashboardSummary {
     throw new Error('Not implemented YET');
   }


   public taskCommentList(state: Data.TaskList.MyTaskStore): Data.TaskList.Comment[] {
     throw new Error('Not implemented YET');
   }

}
