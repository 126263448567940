import FDataTableMDP from '@/components/table/FDataTableMDP';
import FCellBtnMDP from '@/components/table/cell/FCellBtnMDP';
import FCellDateMDP from '@/components/table/cell/FCellDateMDP';
import FCellRouterLinkMDP from '@/components/table/cell/FCellRouterLinkMDP';

export default class MonthlyCollectionFDataTableMDP extends FDataTableMDP {
  parent: any;
  constructor({ parent }: { parent: any }) {
    super({
      title: 'Monthly Collection',
      myRefName: 'monthlyCollectionFDataTableRef',
      enableSerialNumber: true,
      enablePagination: false,
      hideDefaultFooter: true,
      enableFooter: true
    });
    this.parent = parent;

    this.addColumn({
      label: "Collected Date",
      dataSelectorKey: 'valueDate',
      columnCellMDP: new FCellDateMDP(),
    })
      .addColumn({
        label: "File ID",
        dataSelectorKey: "clientFileNumber",
        enableCopy: true,
        columnCellMDP: new FCellBtnMDP({
          color: 'secondary',
          onClick: (item) => {
            // this.parent.gotoClientFileInfo(item);
          },
        }),
      })
      .addColumn({
        label: "Remote ID",
        dataSelectorKey: 'fppClientRemoteId',
        enableCopy: true,

      })
      .addStatusColumn({
        label: "Transaction Purpose",
        dataSelectorKey: 'txnPurpose',
        // enableCopy: true,
      })
      .addCurrencyColumn({
        label: "Collected amount",
        dataSelectorKey: 'msf',
        enableTotal: true,
      })

  }
}


