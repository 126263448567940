
import * as Data from '@/../src-gen/data';
import {ClientFileSummaryGetters} from '@/../src-gen/store/clientfile-store-support';

export class ClientFileSummaryInit {
    public static initState(): Data.ClientFile.ClientFileSummary  { return new Data.ClientFile.ClientFileSummary(); }
}

export class ClientFileSummaryGettersCode implements ClientFileSummaryGetters {


   public fiEMandateList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiEMandateList[] {
     throw new Error('Not implemented YET');
   }


   public clientFileBasicInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClientFileBasicInfo {
     throw new Error('Not implemented YET');
   }


   public personalInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClPersonalInfo|undefined {
     throw new Error('Not implemented YET');
   }


   public fiBankInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiBankInfo|undefined {
     throw new Error('Not implemented YET');
   }


   public fiCreditorInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiCreditorInfo|undefined {
     throw new Error('Not implemented YET');
   }


   public fiDocumentList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiDocument[] {
     throw new Error('Not implemented YET');
   }


   public fiPaymentPlanInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPaymentPlanInfo|undefined {
     throw new Error('Not implemented YET');
   }


   public budgetInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.BudgetInfo|undefined {
     throw new Error('Not implemented YET');
   }


   public clCreditorList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClCreditor[] {
     throw new Error('Not implemented YET');
   }


   public fiEMandateSummaryList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiEMandateSummary[] {
     throw new Error('Not implemented YET');
   }


   public fiSignAgreementList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiSSASummary[] {
     throw new Error('Not implemented YET');
   }


   public fiPaymentList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPayment[] {
     throw new Error('Not implemented YET');
   }


   public fiPaymentTransactionList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPaymentTransaction[] {
     throw new Error('Not implemented YET');
   }


   public fileSummary(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FileSummary|undefined {
     throw new Error('Not implemented YET');
   }


   public fiClientFileEnrollmentSummary(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClientFileEnrollmentSummary {
     throw new Error('Not implemented YET');
   }

}
