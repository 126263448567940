
import * as Data from '@/../src-gen/data';
import {MyClientFileStoreGetters} from '@/../src-gen/store/clientfile-store-support';

export class MyClientFileStoreInit {
    public static initState(): Data.ClientFile.MyClientFileStore  { return new Data.ClientFile.MyClientFileStore(); }
}

export class MyClientFileStoreGettersCode implements MyClientFileStoreGetters {


   public myClientFileList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myEMandatePendingCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myEMandateActiveCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myMSFPendingCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myMSFPaidCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myActiveCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myAgreementCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myLeadCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public myHoldCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public msfEMandateEligibleCFList(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.MyClientFile[] {
     throw new Error('Not implemented YET');
   }


   public clientFileDashboardSummary(state: Data.ClientFile.MyClientFileStore): Data.ClientFile.ClientFileDashboardSummary {
     throw new Error('Not implemented YET');
   }

}
