<template>
  <div class="InstitutionDashboard">
    <ConversionSummaryInfoMobile class="mobileDeviceNO" />
    <ConversionSummaryInfo class="mobileDeviceOFF" />
    <div class="pa-3 fadeIn">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <div class="chart-container">
            <div class="filterSection DFMobile">
              <div class="leftContainer">
                <v-list-item-title
                  class="text-body-2 font-weight-bold py-2 colorText"
                  >Daily Footfalls
                </v-list-item-title>
                <f-tooltip tooltipText="Showing data as of today.">
                  <v-icon color="colorText" small>
                    mdi-information-variant-circle-outline</v-icon
                  >
                </f-tooltip>
              </div>
              <div class="filter">
                <div
                  class="filterContainer"
                  v-for="(filterData, indx) in filterList"
                  :key="indx"
                >
                  <div
                    :class="{
                      filterCard: true,
                      activefilterCard: filterValue === filterData.filterType,
                    }"
                    @click="filterAPI(filterData.filterType)"
                  >
                    {{ filterData.title }}
                  </div>
                  <div v-if="filterList.length - 1 != indx">
                    <div
                      v-if="filterValue !== filterData.filterType"
                      class="divider"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <component
                v-if="!!dailyFunnelC3CharMetaData && dailyFunnelFilter"
                :ref="dailyFunnelC3CharMetaData.myRefName"
                :is="dailyFunnelC3CharMetaData.componentName"
                :value="dailyFunnelList"
                v-bind="dailyFunnelC3CharMetaData.props"
              ></component>
              <component
                v-if="!!monthlyFunnelC3CharMetaData && monthlyFunnelFilter"
                :ref="monthlyFunnelC3CharMetaData.myRefName"
                :is="monthlyFunnelC3CharMetaData.componentName"
                :value="monthlyFunnelList"
                v-bind="monthlyFunnelC3CharMetaData.props"
              ></component>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <div class="chart-container">
            <div class="searchContainer" v-if="!!showDemographicSearchForm">
              <component
                :ref="demographicFSearchFFormMDP.myRefName"
                :is="demographicFSearchFFormMDP.componentName"
                :value="selectModel(footFallsInputLocal, undefined)"
                @input="
                  (newValue) =>
                    updateModel(footFallsInputLocal, newValue, undefined)
                "
                v-bind="demographicFSearchFFormMDP.props"
              >
              </component>
            </div>
            <div class="filterSection">
              <div>
                <div class="leftContainer">
                  <v-list-item-title
                    class="text-body-2 font-weight-bold colorText"
                    >Demographics
                  </v-list-item-title>
                  <f-tooltip tooltipText="Showing data as of yesterday 11PM.">
                    <v-icon color="colorText" small>
                      mdi-information-variant-circle-outline</v-icon
                    >
                  </f-tooltip>
                </div>
                <p class="sunHeading">Footfall & Conversion</p>
              </div>
              <!-- <div class="rightContainer" hidden>
                <div
                  class="filterIocn"
                  v-if="!showDemographicSearchForm"
                  @click="handleDemographicSearchFormClick()"
                >
                  <v-icon color="#5599ff" small>mdi mdi-filter</v-icon>
                </div>
              </div> -->
            </div>
            <MapChart
              v-if="!!demographyListLocal && demographyList"
              :dataMetaDataList="demographyListLocal"
              tooltipLabel="Customer"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <div class="chart-container">
            <div class="searchContainer" v-if="!!showSearchForm">
              <component
                :ref="pcfSearchFFormMDPMetaData.myRefName"
                :is="pcfSearchFFormMDPMetaData.componentName"
                :value="selectModel(partnerClientFunnelInputLocal, undefined)"
                @input="
                  (newValue) =>
                    updateModel(
                      partnerClientFunnelInputLocal,
                      newValue,
                      undefined
                    )
                "
                v-bind="pcfSearchFFormMDPMetaData.props"
              >
              </component>
            </div>
            <div class="filterSection">
              <div class="leftContainer">
                <v-list-item-title
                  class="text-body-2 font-weight-bold py-2 colorText"
                  >Conversion Funnel
                </v-list-item-title>
                <f-tooltip tooltipText="Showing data as of today.">
                  <v-icon color="colorText" small>
                    mdi-information-variant-circle-outline</v-icon
                  >
                </f-tooltip>
              </div>
              <div class="rightContainer" v-if="!showSearchForm">
                <p class="sunHeading">Showing MTD Data</p>
                <div class="filterIocn" @click="handleSearchFormClick()">
                  <v-icon color="#5599ff" small>mdi mdi-filter</v-icon>
                </div>
              </div>
            </div>
            <component
              v-if="!!conversionFunnelFBarFC3ChartMetaData"
              :ref="conversionFunnelFBarFC3ChartMetaData.myRefName"
              :is="conversionFunnelFBarFC3ChartMetaData.componentName"
              :value="conversionFunnelListLocal"
              v-bind="conversionFunnelFBarFC3ChartMetaData.props"
            ></component>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import FDataTable from "@/components/table/FDataTable.vue";
import OnboarderFilesFDataTableMDP from "./OnboarderFilesFDataTableMDP";
import UnVerifiedFileFDataTableMDP from "./UnVerifiedFileFDataTableMDP";
import Helper from "@/util/Helper";
import FLineFC3ChartMDP from "@/components/C3Chart/FLineFC3ChartMDP";
import FXAxisMDP from "@/components/C3Chart/FXAxisMDP";
import FChartLineMDP from "@/components/C3Chart/FChartLineMDP";
import FC3Chart from "@/components/C3Chart/FC3Chart.vue";
import { ChartType } from "@/components/C3Chart/FC3ChartMDP";
import MapChart from "@/components/mapchart/MapChart.vue";
import ConversionSummaryInfo from "./ConversionSummaryInfo.vue";
import FBarFC3ChartMDP from "@/components/C3Chart/FBarFC3ChartMDP";
import FPointLabelsMDP from "@/components/C3Chart/FPointLabelsMDP";
import FYAxisMDP from "@/components/C3Chart/FYAxisMDP";
import PCFSearchFFormMDP from "./PCFSearchFFormMDP";
import ModelVue from "@/components/ModelVue";
import FForm from "@/components/form/FForm.vue";
import DemographicFSearchFFormMDP from "./DemographicFSearchFFormMDP";
import ConversionSummaryInfoMobile from "./ConversionSummaryInfoMobile.vue";
import FTooltip from "@/components/FTooltip.vue";

@Component({
  components: {
    "f-tooltip": FTooltip,
    FForm,
    FDataTable,
    FC3Chart,
    MapChart,
    ConversionSummaryInfo,
    ConversionSummaryInfoMobile,
  },
})
export default class InstitutionDashboard extends ModelVue {
  @Store.Getter.Insight.InsightStore.demographyOutput
  demographyList: [];

  @Store.Getter.Insight.InsightStore.partnerClientConversionFunnelOutput
  partnerClientConversionFunnelOutput: Data.Insight.PartnerClientConversionFunnelOutput;

  @Store.Getter.Insight.InsightStore.dailyFunnelOutput
  dailyFunnelList: [];

  @Store.Getter.Insight.InsightStore.monthlyFunnelOutput
  monthlyFunnelList: [];

  dailyFunnelInput: Data.Insight.GetDailyFunnelInput =
    new Data.Insight.GetDailyFunnelInput();

  partnerClientFunnelInput: Data.Insight.GetPartnerClientFunnelInput =
    new Data.Insight.GetPartnerClientFunnelInput();

  monthlyFunnelInput: Data.Insight.GetMonthlyFunnelInput =
    new Data.Insight.GetMonthlyFunnelInput();
  filterValue: string = "";

  showSearchForm: boolean = false;
  showDemographicSearchForm: boolean = false;

  filterList = [
    {
      title: "7D",
      filterType: "last7DaysToDate",
    },
    {
      title: "1M",
      filterType: "thirtyDaysAgo",
    },
    {
      title: "MTD",
      filterType: "startOfMonth",
    },
    {
      title: "1Y",
      filterType: "yearAgo",
    },
    {
      title: "YTD",
      filterType: "startOfYear",
    },
  ];

  get conversionFunnelListLocal() {
    let conversionFunnelList: any = [];
    if (this.partnerClientConversionFunnelOutput) {
      conversionFunnelList = [
        {
          label: "SignUp",
          clientFileCount: this.partnerClientConversionFunnelOutput.leadCount,
        },
        // {
        //   label: "Credit Pull",
        //   clientFileCount:
        //     this.partnerClientConversionFunnelOutput.creditReportCount,
        // },
        {
          label: "Program-Gen",
          clientFileCount:
            this.partnerClientConversionFunnelOutput.programGenCount,
        },
        {
          label: "Agreement Count",
          clientFileCount:
            this.partnerClientConversionFunnelOutput.agreementCount,
        },
        {
          label: "Onboarded",
          clientFileCount:
            this.partnerClientConversionFunnelOutput.onBoardingFeePaidCount,
        },

        {
          label: "Mandate",
          clientFileCount:
            this.partnerClientConversionFunnelOutput.eMandateDoneCount,
        },
      ];
    }
    return conversionFunnelList;
  }

  get demographyListLocal() {
    let data: any = [];
    this.demographyList.map((value: any, key: number) => {
      const { state, customerCount, ccDebtCount, plDebtCount } = value;
      data.push({
        id: state,
        value: customerCount,
      });
    });
    return data;
  }

  get filterValueLocal() {
    return this.filterValue;
  }
  set filterValueLocal(newValue) {
    this.filterValueLocal = newValue;
  }

  get dailyFunnelFilter() {
    return (
      this.filterValueLocal == "last7DaysToDate" ||
      this.filterValueLocal == "thirtyDaysAgo" ||
      this.filterValueLocal == "startOfMonth"
    );
  }

  get monthlyFunnelFilter() {
    return (
      this.filterValueLocal == "yearAgo" ||
      this.filterValueLocal == "startOfYear"
    );
  }

  get partnerClientFunnelInputLocal() {
    (this.partnerClientFunnelInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.partnerClientFunnelInput.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    return this.partnerClientFunnelInput;
  }

  get footFallsInputLocal() {
    (this.partnerClientFunnelInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.partnerClientFunnelInput.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    return this.partnerClientFunnelInput;
  }

  get partnerId() {
    return localStorage.getItem("partnerId") || "";
  }

  get pcfSearchFFormMDPMetaData() {
    return new PCFSearchFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }

  get demographicFSearchFFormMDP() {
    return new DemographicFSearchFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }

  get dailyFunnelC3CharMetaData() {
    return new FLineFC3ChartMDP({
      xAxis: new FXAxisMDP({
        xAxisLabelKey: "ddMM",
      }),
      colorPattern: ["#e8657f", "#699ddc", "#6fd567"],
    })
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "leadCount",
          legend: "Footfall",
        })
      )
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "conversionCount",
          legend: "Onboarding",
        })
      )
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "mandateDoneCount",
          legend: "Mandate",
        })
      )
      .getMetaData();
  }

  get monthlyFunnelC3CharMetaData() {
    return new FLineFC3ChartMDP({
      xAxis: new FXAxisMDP({
        xAxisLabelKey: "mmYY",
      }),
    })
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "leadCount",
          legend: "Footfall",
        })
      )
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "conversionCount",
          legend: "Onboarding",
        })
      )
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "mandateDoneCount",
          legend: "Mandate",
        })
      )
      .getMetaData();
  }

  // conversionFunnelFBarFC3ChartMetaData
  conversionFunnelFBarFC3ChartMetaData = new FBarFC3ChartMDP({
    xAxis: new FXAxisMDP({
      xAxisLabelKey: "label",
    }),
    pointLabels: new FPointLabelsMDP({
      percentageValue: true,
    }),
  })
    .addBar(
      new FChartLineMDP({
        dataSelectorKey: "clientFileCount",
        legend: "Stage",
      })
    )
    .getMetaData();

  clearSearchForm() {
    this.showSearchForm = false;
  }

  getPartnerClientFunnel() {
    Action.Insight.GetPartnerClientFunnel.execute(
      this.partnerClientFunnelInputLocal,
      (output) => {}
    );
  }

  getDailyFunnel(fromDate: string, toDate: string) {
    this.dailyFunnelInput.fromDate = fromDate;
    this.dailyFunnelInput.toDate = toDate;
    Action.Insight.GetDailyFunnel.execute(
      this.dailyFunnelInput,
      (output) => {}
    );
  }

  filterAPI(filter: string) {
    this.filterValue = filter;
    switch (filter) {
      case "last7DaysToDate":
        this.getDailyFunnel(
          Helper.dateMTDWithCurrent().last7DaysToDate,
          Helper.dateMTDWithCurrent().currentDate
        );
        break;

      case "thirtyDaysAgo":
        this.getDailyFunnel(
          Helper.dateMTDWithCurrent().thirtyDaysAgo,
          Helper.dateMTDWithCurrent().currentDate
        );
        break;

      case "startOfMonth":
        this.getDailyFunnel(
          Helper.dateMTDWithCurrent().startOfMonth,
          Helper.dateMTDWithCurrent().currentDate
        );
        break;

      case "yearAgo":
        this.getMonthlyFunnel(
          Helper.dateMTDWithCurrent().yearAgo,
          Helper.dateMTDWithCurrent().currentDate
        );
        break;

      case "startOfYear":
        this.getMonthlyFunnel(
          Helper.dateMTDWithCurrent().startOfYear,
          Helper.dateMTDWithCurrent().currentDate
        );
        break;

      default:
        this.getDailyFunnel(
          Helper.dateMTDWithCurrent().last7DaysToDate,
          Helper.dateMTDWithCurrent().currentDate
        );
        break;
    }
  }

  getPartnerClientDemography() {
    Action.Insight.GetPartnerClientDemography.execute((output) => {
      this.clearDemographicSearchForm();
    });
  }

  getMonthlyFunnel(fromDate: string, toDate: string) {
    this.monthlyFunnelInput.fromDate = fromDate;
    this.monthlyFunnelInput.toDate = toDate;
    Action.Insight.GetMonthlyFunnel.execute(
      this.monthlyFunnelInput,
      (output) => {}
    );
  }

  getPartnerConversionSummary() {
    Action.Insight.GetPartnerConversionSummary.execute((output) => {});
  }

  handleShowMTDDataButtonClick() {
    (this.partnerClientFunnelInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.partnerClientFunnelInput.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    this.getPartnerClientFunnel();
    this.clearSearchForm();
  }

  handleSearchFormClick() {
    this.showSearchForm = true;
  }

  clearDemographicSearchForm() {
    this.showDemographicSearchForm = false;
  }

  handleDemographicSearchFormClick() {
    this.showDemographicSearchForm = true;
  }

  mounted() {
    this.filterAPI("last7DaysToDate");
    this.getPartnerClientDemography();
    this.getPartnerClientFunnel();
    this.getPartnerConversionSummary();
  }
}
</script>

<style lang="css" scoped>
p {
  margin: 0;
}
.colorText {
  color: #4e4e4e;
}
.chart-container {
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #f7f8fb;
  min-height: 410px;
}
.filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.filterCard {
  width: 40px;
  height: 30px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* margin: 0 5px; */
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, font-weight 0.3s;
}

.filterCard.activefilterCard {
  background: #f7f8fb;
  color: #5599ff;
  font-weight: bold;
}
.divider {
  width: 1px;
  height: 18px;
  background: #e0e0e0;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filterIocn {
  cursor: pointer;
  color: #5599ff;
  background: #f7f8fb;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
}
.searchContainer {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.demographyCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fef1e6;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 5px;
  background: #fef1e6;
}
.demographyState {
  color: #004479;
  font-size: 10px;
  font-weight: bold;
}
.demographyNumber {
  color: #f36f21;
  font-size: 10px;
  font-weight: bold;
}
.animation-wrapper {
  text-align: center;
  height: 340px;
  overflow: auto;
  position: relative;
  color: #4e4e4e;
  box-sizing: border-box;
}

.animation-wrapper::-webkit-scrollbar {
  display: none;
}
.sunHeading {
  font-size: 12px;
  color: #4e4e4e;
  white-space: nowrap;
}
.mobileDeviceOFF {
  display: block;
}
.mobileDeviceNO {
  display: none;
}
.rightContainer,
.leftContainer {
  display: flex;
  flex-direction: row;
  /* width: 30%; */
  align-items: center;
}
.close-btn {
  display: block;
}
@media all and (min-device-width: 360px) and (max-device-width: 540px) {
  .mobileDeviceOFF {
    display: none;
  }
  .mobileDeviceNO {
    display: block;
  }
  .filterSection.DFMobile {
    display: inherit;
  }
  .filterCard {
    width: 60px;
  }
}
</style>




// GetPartnerClientFunnel use this api