
import * as Data from '@/../src-gen/data';
import {RouterStoreMutations} from '@/../src-gen/store/spine-store-support';

export class RouterStoreMutationsCode implements RouterStoreMutations   {


   public updateFlattenedRouterList(state: Data.Spine.RouterStore  ): void  {
     throw new Error('Not implemented YET');
   }


}

