var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_vm.paymentCalculatorFormMetaData.componentName,_vm._b({ref:_vm.paymentCalculatorFormMetaData.myRefName,tag:"component",attrs:{"value":_vm.selectModel(_vm.modelValue, _vm.paymentCalculatorFormMetaData.dataSelectorKey)},on:{"input":(newValue) =>
        _vm.updateModel(
          _vm.modelValue,
          newValue,
          _vm.paymentCalculatorFormMetaData.dataSelectorKey
        )}},'component',_vm.paymentCalculatorFormMetaData.props,false)),_c('div',{staticClass:"d-flex justify-space-around"}),(
      _vm.paymentPlan.ppCalculator.totalMonthlyObligation >
      this.modelValue.budgetInfo.proposedDSPayment
    )?_c('v-alert',{staticClass:"col-8 ma-auto mb-5",attrs:{"dense":"","type":"warning","outlined":""}},[_vm._v(" Monthly Obligation ("+_vm._s(_vm._f("toINR")(_vm.paymentPlan.ppCalculator.totalMonthlyObligation.toFixed(2)))+") greater than Affordability ("+_vm._s(_vm._f("toINR")(this.modelValue.budgetInfo.proposedDSPayment.toFixed(2)))+"). ")]):_vm._e(),_c('v-card',{staticClass:"row ma-2",attrs:{"flat":"","outlined":""}},[_c('v-toolbar',[_c('v-tabs',{attrs:{"background-color":"transparent","color":"secondary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Payment Schedule ")]),(!_vm.hideMSFTab)?_c('v-tab',[_vm._v(" MSF Schedule ")]):_vm._e()],1),_c('span',{staticClass:"mx-2"},[_vm._v("Status: ")]),_c('v-chip',[_vm._v(_vm._s(_vm.paymentPlan.psPlanStatus))])],1),_c('v-tabs-items',{staticClass:"col-12",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[(_vm.showAddPsEntryForm)?_c(_vm.addPsEntryFFormMetaData.componentName,_vm._b({ref:_vm.addPsEntryFFormMetaData.myRefName,tag:"component",attrs:{"value":_vm.selectModel(
                _vm.addPsEntryInput,
                _vm.addPsEntryFFormMetaData.dataSelectorKey
              )},on:{"input":(newValue) =>
                _vm.updateModel(
                  _vm.addPsEntryInput,
                  newValue,
                  _vm.addPsEntryFFormMetaData.dataSelectorKey
                )}},'component',_vm.addPsEntryFFormMetaData.props,false)):_vm._e(),_c(_vm.fPaymentScheduleFDataTableMetaData.componentName,_vm._b({ref:_vm.fPaymentScheduleFDataTableMetaData.myRefName,tag:"component",attrs:{"value":_vm.psEntrySchelduledList}},'component',_vm.fPaymentScheduleFDataTableMetaData.props,false))],1),_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c(_vm.fPSkipedPresentedTableMetaData.componentName,_vm._b({ref:_vm.fPSkipedPresentedTableMetaData.myRefName,tag:"component",attrs:{"value":_vm.psEntryPresentedList}},'component',_vm.fPSkipedPresentedTableMetaData.props,false))],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c(_vm.fFeeFDataTableMetaData.componentName,_vm._b({ref:_vm.fFeeFDataTableMetaData.myRefName,tag:"component",attrs:{"value":_vm.subscriptionFeeScheduleList}},'component',_vm.fFeeFDataTableMetaData.props,false))],1)],1)],1)],1),(!_vm.disabled)?_c('div',{staticClass:"d-flex flex-row align-start flex-wrap justify-space-around pa-2 my-5"},_vm._l((_vm.actionMetaDataListFiltered),function(actionMetaData,indx){return _c('div',{key:indx,class:actionMetaData.boundaryClass},[_c(actionMetaData.componentName,_vm._b({tag:"component"},'component',actionMetaData.props,false))],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }